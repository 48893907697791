<template>
    <div class="content">
        <Nav @link="linkTo" :idx="8"></Nav>
        <div style="width: 1200px;margin: 0 auto;">
            <div v-for="(item, index) in list" :key="index">
                <div class="title1">{{ item.title }}</div>
                <div class="line_block"></div>
                <div v-if="item.desc" class="content_text">
                    {{ item.desc }}
                </div>
                <img v-if="item.img && item.img.src" style="margin: 20px auto;display: block;" :style="'width: ' + item.img.width + ';'" mode="widthFix"
                    :src="item.img.src" />
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Nav from '@/components/Nav'
import Footer from '@/components/Footer'

export default {
    name: 'productService4',
    components: {
        Nav,
        Footer
    },
    data() {
        return {
            list: [{
                title: '应急管理',
                desc: '应急管理运用物联感知监测和融合通讯等技术构建应急指挥驾驶舱，实现资源统一配、现场随时知、任务随时跟。通过无人仓储、资源互联，构建物资管理应用，实现应急物资早准备，应急装备早维护，救援环境早了解。通过GIS地图，即时通讯构建用户端app应用，实现附近情况即时知，任务情况随时达。应急管理依托数据大屏驾驶舱、后台和小程序多终端之间的关联，实现信息汇聚，保障事故发生时能够及时预警通知，及时提出决策信息，实现应急救援智能化、扁平化和一体化指挥作战。',
            }, {
                title: '平台优势',
                desc: '提供实时大数据决策、可在个人终端实现融合通讯技术的去中心化指挥平台。智慧应急指挥调度系统基于物联感知、人工智能、北斗通信、雷达监测、卫星遥感、视播一体、监测预警、音视频融合、GIS地图、数字孪生、无人机引擎等技术；满足本地区、多部门预警信息快速上图发布需求，通过部、省、市、县、乡管理平台对接及各级应急业务的协同联动，实现各部门资源数据信息的“共建、共享、共用”；系统架构设计具有良好的可扩展性和先进性。',
            }, {
                title: '融合通讯',
                desc: '融合通讯系统通过集成多种通信技术，如语音、视频和数据交换，使得应急救援人员在遇到紧急事件时能够即时进行多人兑奖、视频沟通，确保应急救援信号和信息的接收，从而清楚的了解现场情况。这种通讯方式解决了传统上报方式单一、无法准备反馈现场情况的难题，为应急决策提供了更有效地依据。此外，融合通信技术还涵盖了卫星通信、物联网感知等新型技术装备的配备使用，提升了无人化、智能化救援能力。通过这种能力互补的多种通信装备，形成了宽窄融合、公共结合的一体化应急指挥通信体系，进一步增强了应急救援的综合能力和效率，成为应急救援事业中不可或缺的力量。',
                img: {
                    src: require('@/assets/img/prod4Img/img1.png'),
                    width: '1023px',
                    header: '332px'
                }
            }, {
                title: '应急指挥',
                desc: '运用物联感知监测和融合通讯等技术构建应急指挥驾驶舱，实现资源统一配、现场随时知、任务随时跟。采用扁平化指挥机制，确保救援运转灵活、信息快速传递、指挥有效度高，变多头分散指挥为集中统一调度，提高快速反应和协同作战能力，为决策者提供科学依据，帮助做出正确的决策，减少决策的事件和错误率。应急指挥旨在提高应对灾害风险的能力，保护人民生命财产安全，维护社会公共安全。',
                img: {
                    src: require('@/assets/img/prod4Img/img2.png'),
                    width: '100%',
                    header: '414px'
                }
            }, {
                title: '应急指挥-驾驶舱',
                desc: '驾驶舱可以更加直观看出现场救援情况、救援力量和应急物资与装备等，能够辅助提高应急管理部门信息化管理水平和应急事件处理的准确性、高效性，方便及时决策。应急救援驾驶舱设计注重快速响应，考虑了多功能性，能够适应不同的救援场景和任务，为应急救援工作提供了强有力的支持，大大提高了应对各种紧急情况的能力和效率。',
                img: {
                    src: require('@/assets/img/prod4Img/img3.png'),
                    width: '780px',
                    header: '500px'
                }
            }, {
                title: '资源管理',
                desc: '通过无人仓储、资源互联，构建物资管理应用，实现应急物资早准备，应急装备早维护，救援环境早了解，在应对突发事件时，应急物资管理系统能够快速获取物资的需求和供应信息，对物资进行智能调度和分配，确保物资能够及时到达需要的地方，最大程度的满足救援需求，提高物资管理的效率和质量，确保物资能够得到合理利用。传统的物资管理方式往往需要大量的人力、物力和财力投入，而且管理难度大，容易出现各种风险和问题。而应急物资管理系统则可以通过信息化手段，实现智能化和自动化，降低资源管理风险和成本，提高资源管理的效益和可持续性。',
                img: {
                    src: require('@/assets/img/prod4Img/img4.png'),
                    width: '100%',
                    header: '347px'
                }
            }, {
                title: '呼麦app',
                desc: '通过GIS地图，即时通讯构建用户端app应用，实现附近情况即时知，任务情况随时达。面向赶赴途中、救援现场等不同作战环境使用需求，结合各项应急指挥业务系统，实现多终端、多数据、多业务的融合移动应用，实现现场应急救援的移动化终端及时获取指挥中心综合决策信息，提高应急办公和处置效率。主要包括任务管理、事件上报、即时通信、会议会商、视频转发和应急通信录等功能',
                img: {
                    src: require('@/assets/img/prod4Img/img5.png'),
                    width: '968px',
                    header: '597px'
                }
            }]
        }
    },
    created() { },
    mounted() {
        window.scrollTo(0, 0);
    },
    methods: {
        linkTo(routerName) {
            this.$router.push(routerName)
        },
    }
}

</script>
<style lang='less' scoped>
@media screen and (min-width: 992px) {

    // PC端
    .content_text {
        font-size: 20px;
        letter-spacing: 2px;
        line-height: 1.5;
        text-indent: 2em;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #666666;
    }

    .title1 {
        font-size: 34px;
        color: #1F1836;
        text-align: center;
        margin-top: 80px;
        margin-bottom: 20px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 400;
    }

    .title2 {
        font-size: 22px;
        color: #1F1836;
        font-weight: 400;
        margin-bottom: 15px;
        margin-top: 25px;
    }

    .line_block {
        margin: 0 auto;
        width: 51px;
        height: 2px;
        background: #2890FC;
        margin-bottom: 43px;
    }
}
</style>